import "./App.css";
import React  from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {
  return (
    <Router>
      <Switch>
      <Route path="*">
    <div className="container p-5">
      <div class="row justify-content-md-center">
        <div class="col-md-auto h1">We will be back soon ..</div>
        
      </div>
      <div class="row justify-content-md-center">
      <div class="col-md-auto h4">My youtube channel <a href="https://www.youtube.com/coderspirit">click</a></div>
      </div>
    </div>
    </Route>
    </Switch>
    </Router>
  );
}

export default App;
